import { SET_SET_SETTINGS } from '../_types';
import * as func from '../../utils/functions';

const initialState = {

    sources: ['CRM', 'Flyer', 'Referral', 'SMS', 'InstaGram', 'FaceBook', 'LinkedIn', 'Website'],
    statuses: ['Imported', 'New', 'In progress', 'Unresponsive', 'Not interested', 'Closed'],

    users: func.getStorageJson('users') || [],
    projects: func.getStorageJson('projects') || [],
    categories: func.getStorageJson('categories') || [],

    navigation: [
        {
            code: 'fin', name: 'Finance', icon: 'fas fa-coins', link: 'finance',
            subs: [
                { code: 'fin_ovr', name: 'Overview', link: 'finance/overview', subs: [] },
                { code: 'fin_trx', name: 'Transactions', link: 'finance/transactions', subs: [], },
                { code: 'fin_rep', name: 'Reports', link: 'finance/reports', subs: [], },
            ]
        },
        {
            code: 'crm', name: 'CRM', icon: 'fas fa-address-card', link: 'crm',
            subs: [
                { code: 'crm_ovr', name: 'Overview', link: 'crm/overview', subs: [] },
                { code: 'crm_cnt', name: 'Contacts', link: 'crm/contacts', subs: [], },
                { code: 'crm_tsk', name: 'Pending tasks', link: 'crm/tasks', subs: [], },
            ]
        },
        {
            code: 'act', name: 'Git Actions', icon: 'fas fa-fw fa-play-circle', link: 'git-actions', subs: [], rules: [],
        },
        {
            code: 'set', name: 'Settings', icon: 'fas fa-fw fa-cogs', link: 'settings', subs: [],
            rules: [
                { code: 'set_ctg', name: 'Categories' },
                { code: 'set_prj', name: 'Projects' },
                { code: 'set_usr', name: 'Users' },
                { code: 'set_rol', name: 'Roles' },
            ],
        },
    ]
};

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;

        case SET_SET_SETTINGS:
            return {
                ...state,
                [action.key]: action.value
            };
    }
};


export default dataReducer;