import React from 'react';
import { func } from '../utils';

const Projects = (props) => {

    const { _data: { projects } } = props;

    return (
        <React.Fragment>
            <div id="Projects" className="grid gap-4 justify-content-end">
                {projects.map(project => (
                    <div key={project.id} className="">
                        <div className="card text-center">
                            <div className="card-header p-1" style={{ backgroundColor: project.color, }}>
                                <div className="text-white">{project.name}</div>
                            </div>
                            <div className="card-body p-2">
                                <div><b>{func.numberFormat(project.balance, 3)}</b></div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
}

export default Projects;