const config = {
    env: process.env.REACT_APP_STAGE || 'local',
    dbpref: 'bk_',
    server: process.env.REACT_APP_API_URL || 'http://localhost/anchoratechs/bk-api/',
    version: require('../../package.json').version,
    sentry_dns: process.env.REACT_APP_SENTRY_DNS || '',
    
    // S S O
    sso_api: process.env.REACT_APP_SSO_API_URL || 'http://localhost:3138/',
    sso_app: process.env.REACT_APP_SSO_APP_URL || 'http://localhost:3139/',
};

export default config;