import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import localeIntl from './assets/intl/data.json';
import queryString from 'query-string';

import { axius, func, config } from './utils';
import { Loading } from './components';

import * as authAct from './store/auth/_authActions';
import * as dataAct from './store/data/_dataActions';

const Main = React.lazy(() => import('./Main'));
const TriPadi = React.lazy(() => import('./TriPadi'));
const Arbitrage = React.lazy(() => import('./Arbitrage'));

const App = (props) => {

  const { _utils: { lang }, _auth: { logg, token } } = props;

  const [loading, setLoading] = React.useState(false);

  const qs = queryString.parse(window.location.search);
  const isLoggedIn = !!(logg?.id && token);
  const isCompliant = true;

  React.useEffect(() => {
    token && initApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (qs.sso) {
      setLoading(true);
      func.setStorage('sso_token', qs.sso.toString());
      verifySsoToken();
    } else {
      verifySsoToken();
      setInterval(() => {
        verifySsoToken();
      }, 60000);
    }
    // eslint-disable-next-line 
  }, [window.location.search]);

  const verifySsoToken = () => {
    axius.get(`sso`, { case: 'auth' }).then(res => {
      switch (res.status) {
        default:
          break;

        case 200:
          props.setSSO(res.sso);
          if (qs.sso) {
            props.signInSuccess(res.token, res.user);
            setTimeout(() => {
              func.redirect('/');
            }, 100);
          }
          break;

        case 401:
          axius.signOutSuccess();
          break;

      }
    });
  }

  const initApp = () => {
    Promise.all([
      axius.get('users'),
      axius.get('categories'),
      axius.get('users', { id: logg.id || '0', logg: 'yes' }),
    ]).then(res => {
      props.setSetSettings('users', res[0].status === 200 ? res[0].data : []);
      props.setSetSettings('categories', res[1].status === 200 ? res[1].data : []);
      res[2].status === 200 && res[2].data.length === 1 && props.signInSuccess(token, res[2].data[0]);
    });
  }

  if (loading) {
    return <Loading full={true} spinning={true} primary="Authenticating" />;
  } else if (!qs.sso) {
    if (!isLoggedIn) {
      axius.signOutSuccess();
      // return 'false';
    } else if (isLoggedIn && !isCompliant) {
      func.redirect(config.sso_app + `?product=${window.location.origin}`);
      return false;
    }
  }

  return (
    <React.Fragment>
      <React.Suspense fallback={<Loading />}>
        <IntlProvider locale={lang} defaultLocale="en" messages={localeIntl[lang]}>
          <Router>
            <Route path='/survey/tripadi' exact={false} render={p => <TriPadi {...p} {...props} />} />
            <Route path='/survey/arbitrage' exact={false} render={p => <Arbitrage {...p} {...props} />} />

            {!props.history.location.pathname.includes('survey') && (
              <>
                <Route render={() => <Main />} />
              </>
            )}
          </Router>
        </IntlProvider>
      </React.Suspense>
    </React.Fragment>
  );

}

const mapStateToProps = (state) => ({
  _auth: state.auth,
  _utils: state.utils,
});

const mapDispatchToProps = (dispatch) => ({
  setSSO: (data) => {
    dispatch(authAct.setSSO(data));
  },
  signInSuccess: (token, data) => {
    dispatch(authAct.signInSuccess(token, data));
  },
  setSetSettings: (key, value) => {
    dispatch(dataAct.setSetSettings(key, value));
  },
  signOutSuccess: () => {
    dispatch(authAct.signOutSuccess());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(App);