import { SET_SSO, SIGNIN_SUCCESS, SIGNOUT_SUCCESS } from '../_types';
import * as func from '../../utils/functions';

const sso = func.getStorageJson('sso_user') || {};
const logg = func.getStorageJson('user') || {};
const token = func.getStorage('token');

const initialState = {
    sso,
    logg,
    authenticated: token && logg.id,
    token,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;

        case SET_SSO:
            return {
                ...state,
                sso: action.payload.data,
            };

        case SIGNIN_SUCCESS:
            return {
                ...state,
                logg: action.payload.data,
                token: action.payload.token,
                authenticated: true,

            };

        case SIGNOUT_SUCCESS:
            return {
                ...state,
                logg: {},
                token: '',
                authenticated: false,
            };
    }
};


export default authReducer;