import React from 'react';

const MaterialIcon = ({ name, className, onClick }) => {

    return (
        <React.Fragment>
            <div id="MaterialIcon" className={`material-symbols-outlined ${onClick ? 'cursor-pointer hover:text-[#0089ff] transi' : ''} ${className}`} onClick={onClick && onClick}>
                {name}
            </div>
        </React.Fragment>
    );
}

export default MaterialIcon;